import request from '@/utils/service';

// 用户端查询公告分页
export function selectNoticePageForUser(params) {
  return request({
    url: `/notice/selectNoticePageForUser`,
    method: 'post',
    data: params,
    hideloading: true
  });
}

// 用户端查询公告详情
export function selectNoticeById(noticeId) {
  return request({
    url: `/notice/selectById/${noticeId}`,
    method: 'get'
  });
}

// 点赞文章
export function likeThisNotice(params) {
  return request({
    url: `/noticeLike/likeThisNotice`,
    method: 'get',
    params,
    hideloading: true
  });
}